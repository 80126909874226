<template>
  <v-container>
    <v-row align="start">
      <v-col cols="6">
        <!-- Select a Master Site -->
        <!-- <v-select
          :items="availableSites"
          v-model="selectedMasterSite"
          label="Select a Master Site"
          item-text="name"
          item-value="id"
          @change="onMasterSiteChange"
          :disabled="loading"
        ></v-select> -->
        <v-autocomplete
          :items="availableSites"
          v-model="selectedMasterSite"
          label="Select a Master Site"
          item-text="name"
          item-value="id"
          @change="onMasterSiteChange"
          :disabled="loading"
          :search-input.sync="search"
        ></v-autocomplete>

      </v-col>
      <v-col cols="6">
        <!-- Select Children Sites for the selected Master Site -->
        <v-autocomplete
          v-if="selectedMasterSite"
          :items="remainingSites"
          item-text="name"
          item-value="id"
          v-model="selectedChildrenSites"
          label="Select Child Sites"
          multiple
          clearable
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row align="start">
      <v-col cols="6">
        <v-select
          v-if="selectedMasterSite && selectedChildrenSites"
          :items="inverters"
          item-text="description"
          item-value="id"
          v-model="selectedComponents"
          label="Select Components"
          multiple
        ></v-select>
      </v-col>
      <v-col cols="6">
        <div style="text-align: left">
          <v-btn
            :loading="loading"
            @click="createMasterSite"
            :ripple="false"
            :elevation="0"
            class="font-weight-bold text-xs btn-success bg-gradient-success"
            >Create Master Site</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <Grid
          ref="grid"
          :style="{ height: '600px' }"
          :data-items="resultMaster"
          :sortable="true"
          :sort="sort"
          :filterable="true"
          :filter="filter"
          :pageable="pageable"
          :take="take"
          :skip="skip"
          :page-size="pageSize"
          :total="total"
          @sortchange="sortChangeHandler"
          @filterchange="filterChangeHandler"
          @pagechange="pageChangeHandler"
          :detail="cellTemplate"
          :columns="columns"
          @expandchange="expandMasterChange"
          :expand-field="expandedField"
        >
          <template v-slot:myTemplate="{ props }">
            <site-detail :data-item="props.dataItem" />
          </template>
          <!-- <template v-slot:previewTemplate="{ props }">
            <previewcell :data-item="props.dataItem" @click="previewRptList" />
          </template> -->

          <template v-slot:delTemplate="{ props }">
            <deletecell :data-item="props.dataItem" @remove="deleteMasterSite" />
          </template>
        </Grid>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Service from "@/services/Service.js";
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import { process } from "@progress/kendo-data-query";
import { filterBy } from "@progress/kendo-data-query";
import DeleteCell from "../reports/DeleteCell.vue";

import Vue from "vue";
import VueToast from "vue-toast-notification";
// Import one of the available themes
import "vue-toast-notification/dist/theme-default.css";

import SiteDetail from "./SiteDetail.vue"

export default {
  components: {
    Grid: Grid,
    "site-detail": SiteDetail,
    deletecell: DeleteCell,
  },

  data() {
    return {
      loading: false,
      inverters: [],
      allSites: [
        // Sample data
        "Site 1",
        "Site 2",
        "Site 3",
        "Site 4",
      ],
      selectedMasterSite: null,
      selectedChildrenSites: [],
      selectedComponents: [],
      masterSites: [],
      allChildSites: [],
      components: [
        { component_id: 100, site_id: 10, component_name: "Component 1" },
        { component_id: 101, site_id: 10, component_name: "Component 2" },
        { component_id: 102, site_id: 11, component_name: "Component 3" },
      ],
      newGroupName: null,
      cellTemplate: "myTemplate",
      requiredRule: [(v) => !!v || "This field is required"],
      expandedField: "expanded",
      expandedItems: [],
      columns: [
        { field: "id", title: "ID", width: "100px" },
        {
          field: "name",
          title: "Master Site Name",
          width: "650px",
          cell: this.cellFunction,
        },
        { title: "Delete", cell: 'delTemplate', filterable: false, width: "200px" },
      ],
      gridData: [],
      skip: 0,
      take: 5,
      sort: null,
      filter: null,
      pageable: { pageSizes: [5, 10, 25, 50, 100] },
      pageSize: 5,
    };
  },

  created: function () {
    this.getData();
  },
  watch: {
    selectedChildrenSites(newSites, oldSites) {
      // Assuming you have a method or computed property to get inverters by site IDs
      if(newSites.length > 0) {
        this.inverters = this.getInvertersBySiteIds(newSites);
      }
    }
  },
  computed: {
    resultMaster: {
      get: function () {
        return process(this.masterSites, {
          sort: this.sort,
          filter: this.filter,
          take: this.take,
          skip: this.skip,
        });
      },
    },
    total() {
      return this.gridData ? filterBy(this.gridData, this.filter).length : 0;
    },
    availableSites() {
      // remove selected master site and all in master site list
      return this.allSites.filter(site => 
        !this.allChildSites.some(acs => acs.id === site.id) &&
        !this.masterSites.some(ms => ms.id === site.id) 
      );
    },
    remainingSites() {
      // const masterSiteIds = this.masterSiteList.map(masterSite => masterSite.id);
      // return this.allSites.filter(site => !masterSiteIds.includes(site.id));
      const masterSiteIds = [this.selectedMasterSite];
      // TODO: will need to add other master site ids here ^^

      return this.availableSites.filter(site => !masterSiteIds.includes(site.id));
    },
  },
  methods: {
    customFilter (item, queryText, itemText) {
      const hasValue = val => val != null ? val : ''
      const text = hasValue(itemText)
      const query = hasValue(queryText)
      
      return text.toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1
    },
    createMasterSite() {
      this.loading = true;
      const componentIds = this.selectedComponents.map(i => {
        return {
          id: i
        };
      })
      const sites = this.selectedChildrenSites.map(s => {
        return {
          id: s
        };
      });
      Service.createMasterSite(this.selectedMasterSite, sites, componentIds)
        .then((response) => {
          // clear selections and reload data.
          this.selectedMasterSite = null;
          this.selectedChildrenSites = [];
          this.selectedComponents = [];
          
          this.loading = false;

          this.getData();
        })
        .catch((error) => {
          this.loading = false;
          Vue.$toast.error(`Error creating master site`, { position: "top-right" });
          console.log(error);
        });
    },
    getInvertersBySiteIds(siteIds) {
      // Filter the allSites array to get only the selected sites
      const selectedSites = this.allSites.filter(site => siteIds.includes(site.id));

      // Extract inverters from each selected site and flatten the arrays into one
      // const invertersForSelectedSites = selectedSites.flatMap(site => site.inverters);
      const invertersForSelectedSites = selectedSites.flatMap(site => 
        site.inverters.map(inverter => ({
          ...inverter,
          description: `${inverter.description} (${site.name})`
        }))
      );

      return invertersForSelectedSites;
    },
    getData: function () {
      NProgress.start();
      this.loading = true;
      Service.getAllSites()
        .then((response) => {
          const s = response.data.sort((a, b) => a.name.localeCompare(b.name));
          this.allSites = s;
          return Service.getMasterSites();
        })
        .then((response) => {
          this.masterSites = response.data.map((item) => {
            return { ...item, expanded: false };
          });

          return Service.getAllChildSites();
        })
        .then((response) => {
          this.allChildSites = response.data;

          NProgress.done();
          this.loading = false;
        })
        .catch((error) => {
          NProgress.done();
          this.loading = false;
          Vue.$toast.error(`Error loading data`, { position: "top-right" });
          console.log(error);
        });
    },
    onMasterSiteChange() {
      this.selectedChildrenSites = [];
    },
    filterChangeHandler(event) {
      this.filter = event.filter;
    },
    pageChangeHandler(event) {
      this.take = event.page.take;
      this.skip = event.page.skip;
    },
    sortChangeHandler(event) {
      this.sort = event.sort;
    },
    expandMasterChange: function (event) {
      // find by id and update
      const idx = this.masterSites.findIndex((c) => {
        return c.id === event.dataItem.id;
      });
      this.masterSites[idx].expanded = event.value;
    },
    deleteMasterSite(e) {
      console.log(`delete master site  ${e.dataItem.id}`);
      this.loading = true;
      Service.deleteMasterSite(e.dataItem.id)
        .then((response) => {
          
          this.getData();
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
};
</script>
