<template>
  <div class="example-wrapper">
    <Grid
      ref="grid"
      :style="{ height: '350px' }"
      :data-items="result"
      :columns="columns"
      :sortable="true" 
      @sortchange="sortChangeHandler"
      :sort="sort"
    >
      <!-- <template v-slot:myTemplate="{ props }">
        <custom :data-item="props.dataItem" @edit="edit" @remove="remove" />
      </template> -->
      <!-- <grid-toolbar>
        &nbsp;<v-btn title="Add new" :theme-color="'primary'" @click="insert">
          Add new
        </v-btn>&nbsp;
        <v-btn title="Help" :theme-color="'primary'" @click="openDialog">
          Help
        </v-btn>
      </grid-toolbar> -->
      <grid-norecords> There is no data available custom </grid-norecords>
    </Grid>
  </div>
</template>
<script>
import Service from "@/services/Service.js";
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import { Button } from "@progress/kendo-vue-buttons";
import { orderBy } from '@progress/kendo-data-query';

import Vue from "vue";
import VueToast from "vue-toast-notification";
// Import one of the available themes
import "vue-toast-notification/dist/theme-default.css";
Vue.use(VueToast);

export default {
  components: {
    Grid: Grid,
    "grid-toolbar": GridToolbar,
    "grid-norecords": GridNoRecords,
  },
  props: {
        dataItem: Object
    },
  data: function () {
    return {
      helpdialog: false,
      gridData: [],
      itemInEdit: null,
      itemInEdit: undefined,
      columns: [
        { field: "id", editable: false, title: "ID", width: "80px" },
        { field: "name", title: "Component Name", width: "200px" },
        { 
            field: "selected_component", 
            title: "Selected", 
            width: "200px",
            cell: this.cellFunction

        },
      ],
      sort: [
        { field: 'filter_group', dir: 'asc' }, // Default sorting by name in ascending order
      ],
    };
  },
  computed: {
    result: {
      get: function () {
        return orderBy(this.gridData, this.sort);
      },
    },
  },
  created: function () {
    // this.updatedData = []; //this.createRandomData(this.total);
    this.getData();
    // this.gridData = [];
  },
  methods: {
    cellFunction(h, tdElement, props, listeners) {
      const isChecked = props.dataItem.selected_component;
      
      return h('td', {
        // Add CSS styles to the surrounding div
        style: {
          colspan: 1,
        }
      }, [
        h('input', {
          attrs: {
            type: 'checkbox',
            checked: isChecked
          },
          // Add CSS styles directly to the checkbox
          style: {
            
          },
          on: {
            change: (event) => {
              console.log(`id: ${props.dataItem.id}; checked: ${event.target.checked}`);
              props.dataItem.selected_component = event.target.checked;
              Service.selectChildComponent(props.dataItem.master_site_id, props.dataItem.id, event.target.checked)
                .then((response) => {
                
                  // this.getData();
                })
                .catch((error) => {
                console.log(error);
                });
            }
          }
        })
      ]);
    },
    // onCheckboxChange(event, dataItem) {
    //   dataItem.selected_component = event.target.checked;
    //   // You can also make any other necessary updates or API calls here.
    // },
    getData() {
      this.loading = true;
      
      Service.getChildSiteComponents(this.dataItem.id)
        .then((response) => {
          // TODO: put master_site_id in here... 
          const s = response.data.sort((a, b) => a.name.localeCompare(b.name));
          this.gridData = s.map((item) => {
            return { ...item, expanded: false, master_site_id: this.dataItem.masterSiteId };
          });
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    help() {

    },
    openDialog() {
      this.helpdialog = true;
    },
    closeDialog() {
      this.helpdialog = false;
    },
    cancel() {
      this.itemInEdit = undefined;
    },
    dialogTitle() {
      // this.itemInEdit.ProductID === undefined ? "Add" : "Edit"
      return `${
        this.itemInEdit.id === undefined ? "Add" : "Edit"
      } `;
    },
    cloneProduct(product) {
      return Object.assign({}, product);
    },
    cloneItem(item) {
      return Object.assign({}, item);
    },
   
    sortChangeHandler: function (e) {
      this.sort = e.sort;
    },
  },
};
</script>