var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-9" }),
        _c("div", { staticClass: "col-md-3" }, [_c("templates")], 1),
      ]),
      _c(
        "Grid",
        {
          ref: "grid",
          attrs: {
            "data-items": _vm.result,
            filterable: true,
            filter: _vm.filter,
            "edit-field": "inEdit",
            pageable: _vm.pageable,
            take: _vm.take,
            skip: _vm.skip,
            "page-size": _vm.pageSize,
            total: _vm.total,
            sortable: { mode: "multiple" },
            sort: _vm.sort,
            columns: _vm.columns,
            "selected-field": _vm.selectedField,
            "row-render": _vm.cellFunction,
          },
          on: {
            filterchange: _vm.filterChange,
            sortchange: _vm.sortChangeHandler,
            itemchange: _vm.itemChange,
            dataStateChange: _vm.dataStateChange,
            pagechange: _vm.pageChange,
            rowclick: _vm.onRowClick,
          },
          scopedSlots: _vm._u([
            {
              key: "myCellTemplate",
              fn: function (ref) {
                var props = ref.props
                return [
                  _c("td", [
                    props.dataItem.name
                      ? _c(
                          "div",
                          [
                            _c(
                              "router-link",
                              { attrs: { to: "/sites/" + props.dataItem.id } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(props.dataItem[props.field]) +
                                    " "
                                ),
                              ]
                            ),
                            _c("div", [
                              _c(
                                "div",
                                {
                                  attrs: { id: "inverter_link_button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openWindow(
                                        "/sites/" +
                                          props.dataItem.id +
                                          "/inverters_list"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    staticClass: "mt-1",
                                    attrs: {
                                      src: require("@/assets/img/inverters-link.svg"),
                                      width: "20",
                                      height: "20",
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "mt-1",
                                  attrs: {
                                    id: "monitoring_link_button",
                                    size: "24",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openWindow(
                                        props.dataItem.monitoring_url
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/img/" +
                                        props.dataItem.monitoring_system +
                                        ".png"),
                                    },
                                  }),
                                ]
                              ),
                              props.dataItem.sitePlans
                                ? _c(
                                    "div",
                                    { attrs: { id: "site_plans_button" } },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { size: "24" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openWindow(
                                                props.dataItem.sitePlans
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" mdi-floor-plan ")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                          ],
                          1
                        )
                      : _c("div", [
                          _vm._v(
                            " " + _vm._s(props.dataItem[props.field]) + " "
                          ),
                        ]),
                  ]),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "grid-toolbar",
            { staticClass: "d-flex justify-space-between" },
            [
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                { attrs: { text: "" } },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _vm._v(" More Options "),
                              _c("v-icon", { attrs: { right: "" } }, [
                                _vm._v("mdi-dots-vertical"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "v-list",
                    _vm._l(_vm.menuLinks, function (link) {
                      return _c(
                        "v-list-item",
                        { key: link.text, on: { click: link.action } },
                        [_c("v-list-item-title", [_vm._v(_vm._s(link.text))])],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("grid-norecords", [_vm._v(" There is no data available custom ")]),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.showUploadInvertersModal,
            callback: function ($$v) {
              _vm.showUploadInvertersModal = $$v
            },
            expression: "showUploadInvertersModal",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-spacer",
                [
                  _c(
                    "v-alert",
                    {
                      attrs: { type: "success", dismissible: "" },
                      model: {
                        value: _vm.showUploadInvertersSuccessAlert,
                        callback: function ($$v) {
                          _vm.showUploadInvertersSuccessAlert = $$v
                        },
                        expression: "showUploadInvertersSuccessAlert",
                      },
                    },
                    [_vm._v(" Inverter data was successfully uploaded. ")]
                  ),
                  _c(
                    "v-alert",
                    {
                      attrs: { type: "error", dismissible: "" },
                      model: {
                        value: _vm.showUploadInvertersErrorAlert,
                        callback: function ($$v) {
                          _vm.showUploadInvertersErrorAlert = $$v
                        },
                        expression: "showUploadInvertersErrorAlert",
                      },
                    },
                    [
                      _vm._v(
                        " Error: " + _vm._s(this.uploadInvertersError) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("v-card-title", [_vm._v(" Upload Inverters ")]),
              _c(
                "v-card-text",
                [
                  _c("v-file-input", {
                    attrs: {
                      accept: "text/csv",
                      label: "CSV file",
                      required: "",
                    },
                    model: {
                      value: _vm.uploadedInverterFile,
                      callback: function ($$v) {
                        _vm.uploadedInverterFile = $$v
                      },
                      expression: "uploadedInverterFile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("a", {
                    attrs: { href: "" },
                    domProps: { textContent: _vm._s("Download template") },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.downloadInvertersCSVTemplate.apply(
                          null,
                          arguments
                        )
                      },
                    },
                  }),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "font-weight-bold text-xs btn-default bg-gradient-danger",
                      attrs: { ripple: false, elevation: 0, outlined: "" },
                      on: { click: _vm.closeUploadInverters },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "font-weight-bold text-xs btn-success bg-gradient-success",
                      attrs: { ripple: false, elevation: 0 },
                      on: { click: _vm.submitUploadInverter },
                    },
                    [_vm._v(" Submit ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("site-upload-modal", {
        on: { clicked: _vm.newSiteUploaded },
        model: {
          value: _vm.showUploadSitesModal,
          callback: function ($$v) {
            _vm.showUploadSitesModal = $$v
          },
          expression: "showUploadSitesModal",
        },
      }),
      _vm.showSiteMergeModal
        ? _c(
            "k-dialog",
            {
              attrs: {
                width: 1050,
                height: 750,
                title: "Merge Site Configuration",
              },
              on: {
                close: function ($event) {
                  _vm.showSiteMergeModal = false
                },
              },
            },
            [_c("site-merge")],
            1
          )
        : _vm._e(),
      _vm.visibleDialog
        ? _c(
            "k-dialog",
            {
              staticClass: "ml-4",
              attrs: {
                title: "Site Details",
                width: 1400,
                height: 750,
                id: "details_modal",
              },
              on: { close: _vm.toggleDialog },
            },
            [
              _c(
                "div",
                { attrs: { id: "details_div" } },
                [
                  _c(
                    "v-container",
                    {
                      staticStyle: { padding: "3px" },
                      attrs: { id: "details_container" },
                    },
                    [
                      _c("sitedetails", {
                        attrs: {
                          siteId: this.selectedID,
                          insideSiteModal: true,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { width: "300" },
          model: {
            value: _vm.columnDialog,
            callback: function ($$v) {
              _vm.columnDialog = $$v
            },
            expression: "columnDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "text-h5 grey lighten-2" },
                [
                  _vm._v(" Select Columns "),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.columnDialog = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                _vm._l(_vm.site_fields, function (f) {
                  return _c(
                    "div",
                    [
                      _c("v-checkbox", {
                        attrs: { label: "" + f.title, value: f },
                        model: {
                          value: _vm.selected_fields,
                          callback: function ($$v) {
                            _vm.selected_fields = $$v
                          },
                          expression: "selected_fields",
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c("v-btn", { on: { click: _vm.saveColumns } }, [
                    _vm._v(" Save Columns "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }