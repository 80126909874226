<template>
  <div class="example-wrapper">
    <Grid
      ref="grid"
      :style="{ height: '300px' }"
      :data-items="result"
      :columns="columns"
      :sortable="true" 
      :loading="loading"
      @sortchange="sortChangeHandler"
      :sort="sort"
      :detail="cellTemplate"
      @expandchange="expandChange"
      :expand-field="expandedField"
        >
        <template v-slot:delTemplate="{ props }">
            <deletecell
            :data-item="props.dataItem"
            @remove="deleteChildSite"
            />
        </template>
          <template v-slot:myTemplate="{ props }">
            <component-detail :data-item="props.dataItem" />
          </template>
         
      <!-- <template v-slot:myTemplate="{ props }">
        <custom :data-item="props.dataItem" @edit="edit" @remove="remove" />
      </template> -->
      <!-- <grid-toolbar>
        &nbsp;<v-btn title="Add new" :theme-color="'primary'" @click="insert">
          Add new
        </v-btn>&nbsp;
        <v-btn title="Help" :theme-color="'primary'" @click="openDialog">
          Help
        </v-btn>
      </grid-toolbar> -->
      <grid-norecords> There is no data available custom </grid-norecords>
    </Grid>
  </div>
</template>
<script>
import Service from "@/services/Service.js";
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import { Button } from "@progress/kendo-vue-buttons";
import { orderBy } from '@progress/kendo-data-query';
import DeleteCell from "../reports/DeleteCell.vue";

import Vue from "vue";
import VueToast from "vue-toast-notification";
// Import one of the available themes
import "vue-toast-notification/dist/theme-default.css";
import ComponentDetail from "./ComponentDetail.vue";
Vue.use(VueToast);

export default {
  components: {
    Grid: Grid,
    "grid-toolbar": GridToolbar,
    "grid-norecords": GridNoRecords,
     "component-detail": ComponentDetail,
     deletecell: DeleteCell,
  },
  props: {
        dataItem: Object
    },
  data: function () {
    return {
      helpdialog: false,
      gridData: [],
      loading: false,
      itemInEdit: null,
      itemInEdit: undefined,
      cellTemplate: "myTemplate",
      expandedField: "expanded",
      expandedItems: [],
      columns: [
        { field: "id", editable: false, title: "ID", width: "80px" },
        { field: "name", title: "Child Site Name", width: "200px" },
        { field: "monitoringSystem", title: "Monitoring System", width: "200px" },
        { title: "Delete", cell: 'delTemplate', filterable: false, width: "200px" },
      ],
      sort: [
        { field: 'filter_group', dir: 'asc' }, // Default sorting by name in ascending order
      ],
    };
  },
  computed: {
    result: {
      get: function () {
        return orderBy(this.gridData, this.sort);
      },
    },
  },
  created: function () {
    // this.updatedData = []; //this.createRandomData(this.total);
    this.getData();
    // this.gridData = []
  },
  methods: {
    help() {

    },
    getData() {
      this.loading = true;
      Service.getChildSites(this.dataItem.id)
        .then((response) => {
          const s = response.data.sort((a, b) => a.name.localeCompare(b.name));
          this.gridData = s.map((item) => {
            return { ...item, expanded: false };
          });
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    openDialog() {
      this.helpdialog = true;
    },
    closeDialog() {
      this.helpdialog = false;
    },
    cancel() {
      this.itemInEdit = undefined;
    },
    dialogTitle() {
      // this.itemInEdit.ProductID === undefined ? "Add" : "Edit"
      return `${
        this.itemInEdit.id === undefined ? "Add" : "Edit"
      } `;
    },
    cloneProduct(product) {
      return Object.assign({}, product);
    },
    cloneItem(item) {
      return Object.assign({}, item);
    },
   
    sortChangeHandler: function (e) {
      this.sort = e.sort;
    },
    expandChange: function (event) {
      // find by id and update
      const idx = this.gridData.findIndex((c) => {
        return c.id === event.dataItem.id;
      });
      this.gridData[idx].expanded = event.value;
    },
    deleteChildSite(e) {
      console.log(`delete child site  ${e.dataItem.id}`);
      Service.deleteChildSite(this.dataItem.id, e.dataItem.id)
        .then((response) => {
          
          this.getData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>