var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { attrs: { align: "start" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c("v-autocomplete", {
                attrs: {
                  items: _vm.availableSites,
                  label: "Select a Master Site",
                  "item-text": "name",
                  "item-value": "id",
                  disabled: _vm.loading,
                  "search-input": _vm.search,
                },
                on: {
                  change: _vm.onMasterSiteChange,
                  "update:searchInput": function ($event) {
                    _vm.search = $event
                  },
                  "update:search-input": function ($event) {
                    _vm.search = $event
                  },
                },
                model: {
                  value: _vm.selectedMasterSite,
                  callback: function ($$v) {
                    _vm.selectedMasterSite = $$v
                  },
                  expression: "selectedMasterSite",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _vm.selectedMasterSite
                ? _c("v-autocomplete", {
                    attrs: {
                      items: _vm.remainingSites,
                      "item-text": "name",
                      "item-value": "id",
                      label: "Select Child Sites",
                      multiple: "",
                      clearable: "",
                    },
                    model: {
                      value: _vm.selectedChildrenSites,
                      callback: function ($$v) {
                        _vm.selectedChildrenSites = $$v
                      },
                      expression: "selectedChildrenSites",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "start" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _vm.selectedMasterSite && _vm.selectedChildrenSites
                ? _c("v-select", {
                    attrs: {
                      items: _vm.inverters,
                      "item-text": "description",
                      "item-value": "id",
                      label: "Select Components",
                      multiple: "",
                    },
                    model: {
                      value: _vm.selectedComponents,
                      callback: function ($$v) {
                        _vm.selectedComponents = $$v
                      },
                      expression: "selectedComponents",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "6" } }, [
            _c(
              "div",
              { staticStyle: { "text-align": "left" } },
              [
                _c(
                  "v-btn",
                  {
                    staticClass:
                      "font-weight-bold text-xs btn-success bg-gradient-success",
                    attrs: {
                      loading: _vm.loading,
                      ripple: false,
                      elevation: 0,
                    },
                    on: { click: _vm.createMasterSite },
                  },
                  [_vm._v("Create Master Site")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("Grid", {
                ref: "grid",
                style: { height: "600px" },
                attrs: {
                  "data-items": _vm.resultMaster,
                  sortable: true,
                  sort: _vm.sort,
                  filterable: true,
                  filter: _vm.filter,
                  pageable: _vm.pageable,
                  take: _vm.take,
                  skip: _vm.skip,
                  "page-size": _vm.pageSize,
                  total: _vm.total,
                  detail: _vm.cellTemplate,
                  columns: _vm.columns,
                  "expand-field": _vm.expandedField,
                },
                on: {
                  sortchange: _vm.sortChangeHandler,
                  filterchange: _vm.filterChangeHandler,
                  pagechange: _vm.pageChangeHandler,
                  expandchange: _vm.expandMasterChange,
                },
                scopedSlots: _vm._u([
                  {
                    key: "myTemplate",
                    fn: function (ref) {
                      var props = ref.props
                      return [
                        _c("site-detail", {
                          attrs: { "data-item": props.dataItem },
                        }),
                      ]
                    },
                  },
                  {
                    key: "delTemplate",
                    fn: function (ref) {
                      var props = ref.props
                      return [
                        _c("deletecell", {
                          attrs: { "data-item": props.dataItem },
                          on: { remove: _vm.deleteMasterSite },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }