<template>
<td v-if="!dataItem.inEdit" :class="className">{{ dataItem[field]}}</td>
    <td v-else><select class="k-textbox" @change="change">
    <option>SolarEdge</option>
    <option>Fronius</option>
    <option>Also</option>
    <option>Locus</option>
</select></td>
</template>
<script>

export default {
    props: {
        field: String,
        dataItem: Object,
        format: String,
        className: String,
        columnIndex: Number,
        columnsCount: Number,
        rowType: String,
        level: Number,
        expanded: Boolean,
        editor: String
    },
    methods: {
        change(e) {
            this.$emit('change', e, e.target.value);
        }
    }
}
</script>