var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "example-wrapper" },
    [
      _c(
        "Grid",
        {
          ref: "grid",
          style: { height: "350px" },
          attrs: {
            "data-items": _vm.result,
            columns: _vm.columns,
            sortable: true,
            sort: _vm.sort,
          },
          on: { sortchange: _vm.sortChangeHandler },
        },
        [_c("grid-norecords", [_vm._v(" There is no data available custom ")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }