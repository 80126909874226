var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("a", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.user.claims.user_role == "super_user",
          expression: "user.claims.user_role == 'super_user'",
        },
      ],
      attrs: { href: "" },
      domProps: { textContent: _vm._s("Download Full Site List Details") },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.downloadSitesCSVTemplate.apply(null, arguments)
        },
      },
    }),
    _c("br"),
    _c("a", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.user.claims.user_role == "super_user",
          expression: "user.claims.user_role == 'super_user'",
        },
      ],
      attrs: { href: "" },
      domProps: { textContent: _vm._s("Download Full Inverter List details") },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.downloadInvertersCSVTemplate.apply(null, arguments)
        },
      },
    }),
    _c("br"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }