<template>
  <v-dialog v-model="show" width="500">
    <v-card>
      <v-spacer>
        <v-alert
          type="success"
          dismissible
          v-model="showUploadSitesSuccessAlert"
        >
          Site data was successfully uploaded.
        </v-alert>
        <v-alert type="error" dismissible v-model="showUploadSitesErrorAlert">
          Error: {{ this.uploadSitesError }}
        </v-alert>
      </v-spacer>
      <v-card-title> Upload Sites </v-card-title>
      <v-card-text>
        <v-select
          :items="companies"
          required
          outlined
          :rules="[(v) => !!v || 'Company is required']"
          label="Select Company"
          :item-text="'name'"
          :item-value="'id'"
          v-model="companyValue"
          v-on:change="getAccountsByCompany"
        >
        </v-select>
        <v-select
          :items="accounts"
          required
          outlined
          :rules="[(v) => !!v || 'Account is required']"
          label="Select Account"
          :item-text="'name'"
          :item-value="'id'"
          v-model="accountValue"
        ></v-select>
      </v-card-text>
      <v-card-text>
        <v-file-input
          v-model="uploadedSitesFile"
          accept="text/csv"
          label="CSV file"
          class="mt-n8"
          required
        ></v-file-input>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-danger"
          @click="closeUploadSites"
        >
          Cancel
        </v-btn>
        <v-btn
          :loading=loading
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-success bg-gradient-success"
          @click="submitUploadSite"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Service from "@/services/Service.js";
import Vue from "vue";
import VueToast from "vue-toast-notification";
import { DropDownList } from "@progress/kendo-vue-dropdowns";

Vue.use(VueToast);

export default {
  components: {
    dropdownlist: DropDownList,
  },
  props: {
    value: Boolean,
  },
  data() {
    return {
      loading: false,
      uploadSitesError: "",
      uploadedSitesFile: null,
      showUploadSitesErrorAlert: false,
      showUploadSitesSuccessAlert: false,
      accounts: [],
      accountValue: {},
      defaultItem: {
        name: "Select account ...",
        id: 0,
      },
      companies: [],
      companyValue: {},
      defaultCompanyItem: {
        name: "Select company ...",
        id: 0,
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  created: function () {
    this.getData();
  },
  methods: {
    change(e) {
      this.accountValue = { id: e.target.value.id, name: e.target.value.name };
      // this.$emit("change", e, e.target.value);
    },
    closeUploadSites() {
      // this.showUploadSitesModal = false;
      this.show = false;
    },
    getData: function () {
      Service.getCompanies()
        .then((response) => {
          // console.log("getData call... set gridData");
          this.companies = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAccountsByCompany(companyId) {
      Service.getAccountsByCompany(companyId)
        .then((response) => {
          this.accounts = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submitUploadSite() {
      const self = this;

      if (this.uploadedSitesFile) {
        this.loading = true;
        Service.uploadSiteDataV2(this.uploadedSitesFile, this.accountValue)
          // .then(this.handleSuccessfulSitesUpload)
          .then(() => {
            console.log("hello");
            this.$emit("clicked", "someValue");
            Vue.$toast.success(`Site(s) uploaded`, {
              position: "top-right",
              duration: 4000,
            });
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            // this.handleErroneousSitesUpload(error.response.data.error);
            console.log("Error uploading sites:", error.response);
            Vue.$toast.error(`Error uploading site(s)`, {
              position: "top-right",
              duration: 4000,
            });
          });
      } else {
        this.handleErroneousSitesUpload("No file given");
      }
    },
  },
};
</script>

<style>
</style>