var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "td",
    [
      _c(
        "v-btn",
        {
          staticClass: "mx-2",
          attrs: { icon: "", color: "red" },
          on: { click: _vm.removeHandler },
        },
        [_c("v-icon", [_vm._v("mdi-delete")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }