var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "example-wrapper" },
    [
      _c(
        "Grid",
        {
          ref: "grid",
          style: { height: "300px" },
          attrs: {
            "data-items": _vm.result,
            columns: _vm.columns,
            sortable: true,
            loading: _vm.loading,
            sort: _vm.sort,
            detail: _vm.cellTemplate,
            "expand-field": _vm.expandedField,
          },
          on: {
            sortchange: _vm.sortChangeHandler,
            expandchange: _vm.expandChange,
          },
          scopedSlots: _vm._u([
            {
              key: "delTemplate",
              fn: function (ref) {
                var props = ref.props
                return [
                  _c("deletecell", {
                    attrs: { "data-item": props.dataItem },
                    on: { remove: _vm.deleteChildSite },
                  }),
                ]
              },
            },
            {
              key: "myTemplate",
              fn: function (ref) {
                var props = ref.props
                return [
                  _c("component-detail", {
                    attrs: { "data-item": props.dataItem },
                  }),
                ]
              },
            },
          ]),
        },
        [_c("grid-norecords", [_vm._v(" There is no data available custom ")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }