var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.dataItem.inEdit
    ? _c("td", { class: _vm.className }, [
        _vm._v(_vm._s(_vm.dataItem[_vm.field])),
      ])
    : _c("td", [
        _c("select", { staticClass: "k-textbox", on: { change: _vm.change } }, [
          _c("option", [_vm._v("SolarEdge")]),
          _c("option", [_vm._v("Fronius")]),
          _c("option", [_vm._v("Also")]),
          _c("option", [_vm._v("Locus")]),
        ]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }