<template>
  <td v-if="!dataItem.inEdit" :class="className">
      {{ dataItem.billing_plan ? `${dataItem.billing_plan.name}` : "" }}
  </td>
  <td v-else>
    <!-- <dropdownlist
      :data-items="billing_plans"
      :default-item="defaultItem"
      :text-field="'name'"
      :data-item-key="'id'"
      :value="value"
      @change="handleChange"
    >
    </dropdownlist> -->
    <combobox
        :style="{ width: '125px' }"
        :data-items="billing_plans"
        :default-item="defaultItem"
        :text-field="'name'"
        :data-item-key="'id'"
        :value="value"
        @change="handleChange"
        :allow-custom="true"
    ></combobox>
  </td>
</template>
<script>
import Service from "@/services/Service.js";
import { DropDownList, ComboBox } from '@progress/kendo-vue-dropdowns';
import Vue from "vue";
import VueToast from "vue-toast-notification";
// Import one of the available themes
import "vue-toast-notification/dist/theme-default.css";
Vue.use(VueToast);
export default {
  components: {
    dropdownlist: DropDownList,
    combobox: ComboBox
  },
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String,
  },
  data: function () {
    return {
      value: null,
      billing_plans: [],
      defaultItem: { 
        name: 'Select Plan ...',
        id: 0
      }
    };
  },  
  created: function () {
    // console.log(`dataItem: ${this.dataItem}, field: ${this.field}`);
    if(this.dataItem.billing_plan) {
      this.value = {id: this.dataItem.billing_plan.id, name: this.dataItem.billing_plan.name}
    }
    
    this.getData();
    
  },
  methods: {
    getData: function () {
      Service.getBillingPlans()
        .then((response) => {
          this.billing_plans = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleChange(e) {
            if(e.target.value.id == undefined) {
        Service.createBillingPlan({name: e.target.value.name})
        .then((response) => {
          const bp = response.data;
          this.value = {id: bp.id, name: bp.name};
          e.target.value.id = bp.id;
          this.$emit("change", e, bp);
          Vue.$toast.success(`Plan added`, {
            position: "top-right",
            duration: 4000,
          });
        })
        .catch((error) => {
          console.log(error);
        });
      } else {
        this.value = {id: e.target.value.id, name: e.target.value.name};
        this.$emit("change", e, e.target.value);
      }
    },
  },
};
</script>