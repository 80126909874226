<template>
<div>
  <a
    v-show="user.claims.user_role == 'super_user'"
    :href="''"
    v-text="'Download Full Site List Details'"
    @click.prevent="downloadSitesCSVTemplate" 
  >
  </a>
  <br />
  <a 
    v-show="user.claims.user_role == 'super_user'"
    :href="''"
    v-text="'Download Full Inverter List details'"
    @click.prevent="downloadInvertersCSVTemplate" 
  >
  </a>
  <br/>
</div>
</template>

<script>
import Service from '@/services/Service.js';
import Vue from 'vue';

import VueToast from 'vue-toast-notification';
// Import one of the available themes
import 'vue-toast-notification/dist/theme-default.css';

// import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast);

export default {
  name: 'Admin',
  components: {

  },
  data() {
    return {
      reports: [],
    };
  },
  mounted() {
    document.title = "Admin"
  },
  methods: {
    downloadSitesCSVTemplate () {
      NProgress.start()
      Service.downloadSitesCSVTemplate()
        .then(response => {
          this.handleSuccessfulTemplateDownload(response, 'sites.csv')
          NProgress.done()
        })
        .catch(error => {
          console.log('Error getting data:', error.response);
          NProgress.done()
        });
    },
    downloadInvertersCSVTemplate () {
      NProgress.start()
      Service.downloadInvertersCSVTemplate()
        .then(response => {
          this.handleSuccessfulTemplateDownload(response, 'inverters.csv')
          NProgress.done()
        })
        .catch(error => {
          console.log('Error getting data:', error.response);
          NProgress.done()
        });
    },
    handleCSVDownload (response, filename) {
      const blob = new Blob([response.data], { type: 'text/csv' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = filename
      link.click()
      URL.revokeObjectURL(link.href)
    },
    handleSuccessfulTemplateDownload (response, csv) {
      this.handleCSVDownload(response, csv)
    }
  },
  computed: {
    user: function() {
      return this.$store.getters.currentUser;
    },
  },
  created () {
  },
};
</script>

<style lang="scss">

</style>
